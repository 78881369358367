import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Button from "../../Button";
import Select from "../../../components/Form/Select";
import {
  RejectLeaveReasons,
  RejectAuthorisationReasons,
} from "../../../utilities/constants";
import Textarea from "../../Form/Textarea";
import { closeModal } from "../../../slices/modals";

import "../_modal.scss";
import "./_index.scss";
import { useDispatch } from "react-redux";
import { rejectLeaveRequest } from "../../../slices/leave";

import { rejectAuthorisationRequest } from "../../../slices/authorisation";

const ConfirmDeleteModal = ({ id, open, handleClose, data }) => {
  const rejectLeaveDetails = data?.rejectDetails === true ? true : false;
  const rejectAuthorisationDetails =
    data?.rejectAuthDetails === true ? true : false;
  const rejectAuthorisation =
    data?.isLeaveOrAuthorisation === true ? true : false;
  const [reason, setReason] = useState("");
  const [isOther, setIsOther] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (reason.includes("Other")) {
      setIsOther(true);
    } else {
      setIsOther(false);
    }
  }, [reason]);

  const initialValues = {
    reason: "",
    other: data?.row?.descriptionRejectRH
      ? data?.row?.descriptionRejectRH
      : data?.row?.descriptionRejectTL
      ? data?.row?.descriptionRejectTL
      : data?.row?.negotiationDescription
      ? data?.row?.negotiationDescription
      : "",
  };
  const validationSchema = Yup.object({
    reason: Yup.string().required("reason is Required!"),
  });

  const onSubmit = (values, submitProps) => {
    if (isOther === false) {
      values.other = "";
    }

    if (
      values.reason === "" ||
      (values.reason.includes("Other") && values.other === "")
    ) {
      submitProps.setErrors({
        reason: "reason is Required!",
      });
    } else {
      rejectAuthorisation
        ? dispatch(
            rejectAuthorisationRequest({
              id: data?.id,
              descriptionReject: values?.other ? values?.other : values?.reason,
            })
          )
        : dispatch(
            rejectLeaveRequest({
              id: data?.id,
              descriptionReject: values.other ? values.other : values.reason,
            })
          );
      data?.getLeaveStatus({ id: 3, code: ["rejected"], name: "Rejected" });

      dispatch(closeModal("reject-leave-request-modal"));
    }
  };
  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (reason === "backdropClick" && reason !== "escapeKeyDown") {
          handleClose(id);
        }
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="ta-modal confirm-reject-leave"
    >
      <div className="confirm-reject-leave-wrapper">
        <div className="modal-confirm">
          <div className="modal-content">
            <DialogTitle id="alert-dialog-title">
              <Button
                type="button"
                className="close"
                onClick={() => handleClose(id)}
                rightIcon={<CloseIcon />}
              />

              <span className="modal-title">
                {rejectAuthorisation
                  ? "Reject authorisation request"
                  : rejectAuthorisationDetails
                  ? "Reject authorisation details"
                  : rejectLeaveDetails
                  ? "Reject leave details"
                  : "Reject leave request"}
              </span>
            </DialogTitle>
            <DialogContent>
              <span className="modal-text">
                {rejectAuthorisation
                  ? "Do you really want to reject this authorisation request? if yes please add a reason"
                  : rejectAuthorisationDetails
                  ? "This authorisation request is rejected for this reason:"
                  : rejectLeaveDetails
                  ? "This leave request is rejected for this reason:"
                  : "Do you really want to reject this leave request? if yes please add a reason."}
              </span>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {(formik) => {
                  setReason(formik?.values?.reason);

                  return (
                    <Form className="reject-reason-form-wrapper">
                      {!rejectLeaveDetails && !rejectAuthorisationDetails && (
                        <Select
                          value={formik.values.reason}
                          id="reason"
                          borderRadius="20px"
                          marginBottom="26px"
                          marginTop="15px"
                          options={
                            rejectAuthorisation
                              ? RejectAuthorisationReasons
                              : RejectLeaveReasons
                          }
                          onChange={(value) =>
                            formik.setFieldValue("reason", value.value)
                          }
                          maxMenuHeight={isOther ? null : "100px"}
                          error={formik.errors.reason}
                          className={formik.errors.reason ? "error-select" : ""}
                        />
                      )}
                      {rejectLeaveDetails || rejectAuthorisationDetails ? (
                        <Textarea
                          type="text"
                          name="other"
                          placeholder="add a reason..."
                          disabled={true}
                        />
                      ) : (
                        isOther === true && (
                          <Textarea
                            type="text"
                            name="other"
                            placeholder="add a reason..."
                          />
                        )
                      )}
                      {!rejectLeaveDetails && !rejectAuthorisationDetails && (
                        <div className="modal-actions">
                          <Button
                            type="button"
                            text="Reset"
                            className="cancel-btn"
                            onClick={() => handleClose(id)}
                          />
                          <Button
                            type="submit"
                            text="Reject"
                            className="reject-btn"
                          />
                        </div>
                      )}
                    </Form>
                  );
                }}
              </Formik>
            </DialogContent>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmDeleteModal;
